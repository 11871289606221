import { useDidMount } from 'rooks';
import Router from 'next/router';

import withApollo from 'components/withApollo';

const IndexPage = () => {
  useDidMount(() => {
    // Redirect to login
    Router.replace('/login');
  });

  return <div />;
};

export default withApollo(IndexPage);
